body {
    font-size: 12px;
}


.menu {
    font-size: 14px;
}
.dropdown-menu {
    font-size: 18px;
}
.searchbar {
    font-size: 14px;
}
.title {
    word-wrap: break-word;

}
.nav-item {
    font-size: 18px;

}


.dropdown-submenu {
    position: relative;
}
  
.dropdown-submenu.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
    position: -webkit-sticky;

    
}

.menu-cat{
         
    margin-left: 100%;
    margin-top: -15px;
    font-size: -10%;
}


.dropdown-header{
    font-size: 18px;
}
.dropdown-item{
    font-size:14px;
}


html,
body {
    width: 100%;
    padding: 0;
    margin: 0
}
body {
    font: 14px / 25px Arial, Helvetica, sans-serif;
    color: #000000;
   
    background: #000000
}
.sub-title{
    font-size: 20px;
}
.footer {
    background: #000000
}

.main {
    width: 100%;

    border-top: #000000 5px solid;
    background: #F5F5F5;
}

@media only screen and (max-width: 600px) {
.menu-cat {
    margin-left: 15%;
    margin-top: 0px;
    font-size: -10%;
    
    }

}
